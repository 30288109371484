import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Topbar from './Topbar';
import Leftbar from './Leftbar';
import { authInfo } from '../../pages/auth/authSlice';
import { getLocations } from '../../redux/locationSlice';
import { appRoutes } from '../../constants/appRoutes';
import { BREAK_POINT } from '../../constants/appConstants';

const Layout = () => {
  const { isLoggedIn } = useSelector(authInfo);

  const isLaptop = useMediaQuery({ minWidth: BREAK_POINT.lg });

  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(isLaptop);
  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getLocations());
    }
  }, [dispatch, isLoggedIn]);

  if (!isLoggedIn) {
    return <Navigate to={appRoutes.root} />;
  }

  return (
    <React.Fragment>
      <Topbar handleClick={handleClick} showMenu={showMenu} />
      <div>
        <Leftbar showMenu={showMenu} />
        <div className={`page-container ${!showMenu && 'show-page-container'}`}>
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Layout;
