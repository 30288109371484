import React from 'react';
import { REDIRECT_AFTER_TYPE } from '../../constants/appConstants';
import { delete_red, success_icon, warning_icon, not_found_icon } from '../../assets';
import Button from './Button';

const RedirectedPage = ({
  type = REDIRECT_AFTER_TYPE.success,
  firstButtonText = 'Return',
  onFirstButtonClick = () => {
    /* */
  },
  secondButtonText = 'Home',
  onSecondButtonClick = () => {
    /* */
  },
  hideButtons = false,
  hideSecondButton = false,
  children,
}) => {
  const getIcon = () => {
    switch (type) {
      case REDIRECT_AFTER_TYPE.success:
        return success_icon;
      case REDIRECT_AFTER_TYPE.error:
        return warning_icon;
      case REDIRECT_AFTER_TYPE.delete:
        return delete_red;
      case REDIRECT_AFTER_TYPE.notFound:
        return not_found_icon;
      default:
        break;
    }
  };

  return (
    <div className='redirect-page__container'>
      <div>
        <img src={getIcon()} alt='icon' />
      </div>

      <div>{children}</div>
      {!hideButtons && (
        <div className='d-flex align-items-center gap-2'>
          <div>
            <Button className={'redirect-page__first-btn'} onClick={onFirstButtonClick}>
              {firstButtonText}
            </Button>
          </div>
          {!hideSecondButton && (
            <div>
              <Button className={'redirect-page__second-btn'} onClick={onSecondButtonClick}>
                {secondButtonText}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RedirectedPage;
