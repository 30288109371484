import { createSlice } from '@reduxjs/toolkit';
import { appLanguages } from '../constants/appConstants';
import { LEFT_MENU_KEYS } from '../components/layout/layoutUtils';

const initialState = {
  appLanguage: appLanguages.Bengali,
  activeNavItem: LEFT_MENU_KEYS.DASHBOARD,
  homePageMessage: '',
};

const generalSlice = createSlice({
  name: 'generalSlice',
  initialState,
  reducers: {
    resetGeneral(state) {
      Object.assign(state, initialState);
    },
    changeLanguage(state, action) {
      state.appLanguage = action.payload;
    },
    updateHomePageMessage(state, action) {
      state.homePageMessage = action.payload;
    },
    updateActiveNavItem(state, action) {
      state.activeNavItem = action.payload;
    },
  },
  //   extraReducers: (builder) => {},
});

export const { resetGeneral, changeLanguage, updateActiveNavItem, updateHomePageMessage } =
  generalSlice.actions;

//selector
export const generalReducer = (state) => state.general;

export default generalSlice.reducer;
