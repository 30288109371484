import React from 'react';
import Button from '../pattern/Button';
import { access_denied_icon } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appRoutes } from '../../constants/appRoutes';

const AccessDenied = ({
  onButtonClick,
  buttonText,
  hideButton,
  titleText,
  messageText,
  contentHeight = '80vh',
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (typeof onButtonClick === 'function') {
      onButtonClick();
    } else {
      navigate(appRoutes.dashboard);
    }
  };

  return (
    <div className='error-page-container' style={{ height: contentHeight }}>
      <div>
        <img className='' src={access_denied_icon} />
      </div>
      <div className='d-flex flex-column align-items-center'>
        <h2>{titleText ? titleText : t('error.sorry')}</h2>
        <span>{messageText ? messageText : t('error.accessDeniedText')}</span>
      </div>
      {!hideButton && (
        <div className='d-flex gap-2'>
          <Button className={'default-btn'} onClick={handleButtonClick}>
            {buttonText ? buttonText : t('returnDashboard')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AccessDenied;
