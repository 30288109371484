import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { AccessDenied, Layout, NoMatch, ServerError } from './components/index.js';
import PermissionWrapper from './components/pattern/PermissionWrapper.js';
import { PERMISSIONS } from './constants/appConstants.js';

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: 'dashboard',
        lazy: async () => {
          const { Dashboard } = await import('./pages/dashboard/index.js');
          return { Component: Dashboard };
        },
      },
      {
        path: `plan-session/:planSessionId/add-visit-plan`,
        lazy: async () => {
          const { AddVisitPlan } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.SUBMIT_VISIT_PLAN}
                fallback={<AccessDenied />}
              >
                <AddVisitPlan />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'plan-submission-stat/:year',
        lazy: async () => {
          const { PlanSubmissionStat } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <PlanSubmissionStat />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'plan-submission-details/:year',
        lazy: async () => {
          const { PlanSubmissionDetails } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <PlanSubmissionDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'plan-session-list',
        lazy: async () => {
          const { PlanSessionList } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_PLAN_SESSION}
                fallback={<AccessDenied />}
              >
                <PlanSessionList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'add-session-plan',
        lazy: async () => {
          const { AddVisitPlanSession } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_PLAN_SESSION}
                fallback={<AccessDenied />}
              >
                <AddVisitPlanSession />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'update-session-plan/:planSessionId',
        lazy: async () => {
          const { UpdateSessionPlan } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_PLAN_SESSION}
                fallback={<AccessDenied />}
              >
                <UpdateSessionPlan />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'session-plan/:planSessionId',
        lazy: async () => {
          const { SessionPlan } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_PLAN_SESSION}
                fallback={<AccessDenied />}
              >
                <SessionPlan />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'my-plan/:planYear',
        lazy: async () => {
          const { MyPlan } = await import('./pages/visit/index.js');
          return { Component: MyPlan };
        },
      },
      {
        path: 'holiday/:holidayYear',
        lazy: async () => {
          const { Holiday } = await import('./pages/visit/index.js');
          return { Component: Holiday };
        },
      },
      {
        path: 'holiday/:year/add-holiday',
        lazy: async () => {
          const { HolidayManagement } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_HOLIDAY} fallback={<AccessDenied />}>
                <HolidayManagement />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'officer-plans/:officerId',
        lazy: async () => {
          const { OfficerPlans } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.VIEW_OFFICER_DETAIL}
                fallback={<AccessDenied />}
              >
                <OfficerPlans />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'plan-to-be-revised/zone/:zoneId/year/:planYear',
        lazy: async () => {
          const { RevisedPlan } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <RevisedPlan />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'isas-complaint-details/zone/:zoneId/year/:planYear',
        lazy: async () => {
          const { ISASComplaintDetails } = await import('./pages/visit/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <ISASComplaintDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'create-notice',
        lazy: async () => {
          const { CreateNotice } = await import('./pages/notice/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_NOTICE} fallback={<AccessDenied />}>
                <CreateNotice />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'update-notice/:id',
        lazy: async () => {
          const { UpdateNotice } = await import('./pages/notice/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_NOTICE} fallback={<AccessDenied />}>
                <UpdateNotice />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'notice-details/:noticeDetailsId',
        lazy: async () => {
          const { NoticeDetails } = await import('./pages/notice/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_NOTICE} fallback={<AccessDenied />}>
                <NoticeDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'user-notice-details/:noticeDetailsId',
        lazy: async () => {
          const { UserNoticeDetails } = await import('./pages/notice/index.js');
          return { Component: UserNoticeDetails };
        },
      },
      {
        path: 'notice-management',
        lazy: async () => {
          const { NoticeManagement } = await import('./pages/notice/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_NOTICE} fallback={<AccessDenied />}>
                <NoticeManagement />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'notice-list',
        lazy: async () => {
          const { NoticeList } = await import('./pages/notice/index.js');
          return { Component: NoticeList };
        },
      },
      {
        path: 'delete-notice/success',
        lazy: async () => {
          const { Delete } = await import('./pages/cliche/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_NOTICE} fallback={<AccessDenied />}>
                <Delete />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'settings',
        lazy: async () => {
          const { Settings } = await import('./pages/settings/index.js');
          return { Component: Settings };
        },
      },
      {
        path: ':type/succeed',
        lazy: async () => {
          const { Success } = await import('./pages/cliche/index.js');
          return { Component: Success };
        },
      },
      {
        path: ':type/failed',
        lazy: async () => {
          const { Error } = await import('./pages/cliche/index.js');
          return { Component: Error };
        },
      },
      {
        path: ':type/not-found',
        lazy: async () => {
          const { NotFound } = await import('./pages/cliche/index.js');
          return { Component: NotFound };
        },
      },
      {
        path: ':type/deleted',
        lazy: async () => {
          const { Delete } = await import('./pages/cliche/index.js');
          return { Component: Delete };
        },
      },
      {
        path: 'monitoring-bi',
        lazy: async () => {
          const { SurveyList } = await import('./pages/monitoring-bi/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.VIEW_BI} fallback={<AccessDenied />}>
                <SurveyList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'survey-monitoring-bi/:formType',
        lazy: async () => {
          const { MonitoringReport } = await import('./pages/monitoring-bi/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.VIEW_BI} fallback={<AccessDenied />}>
                <MonitoringReport />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'officer-list',
        lazy: async () => {
          const { OfficerList } = await import('./pages/officer/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.VIEW_OFFICER_DETAIL}
                fallback={<AccessDenied />}
              >
                <OfficerList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'officer-details/:officerId',
        lazy: async () => {
          const { OfficerDetails } = await import('./pages/officer/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.VIEW_OFFICER_DETAIL}
                fallback={<AccessDenied />}
              >
                <OfficerDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'profile',
        lazy: async () => {
          const { UserProfile } = await import('./pages/officer/index.js');
          return { Component: UserProfile };
        },
      },
      {
        path: 'institute-list',
        lazy: async () => {
          const { InstituteList } = await import('./pages/institute/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.VIEW_INSTITUTE_DETAIL}
                fallback={<AccessDenied />}
              >
                <InstituteList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'institutes/:instituteEiin',
        lazy: async () => {
          const { InstituteDetails } = await import('./pages/institute/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.VIEW_INSTITUTE_DETAIL}
                fallback={<AccessDenied />}
              >
                <InstituteDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'audit-log',
        lazy: async () => {
          const { ActionLogList } = await import('./pages/audit/index.js');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.MANAGE_AUDIT} fallback={<AccessDenied />}>
                <ActionLogList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'general-visits',
        lazy: async () => {
          const { GeneralVisitList } = await import('./pages/monitoring/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <GeneralVisitList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'general-visits/:visitId',
        lazy: async () => {
          const { GeneralVisitDetails } = await import('./pages/monitoring/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <GeneralVisitDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'curriculum-visits/:visitId',
        lazy: async () => {
          const { CurriculumVisitDetails } = await import('./pages/monitoring/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <CurriculumVisitDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'curriculum-visits',
        lazy: async () => {
          const { CurriculumVisitList } = await import('./pages/monitoring/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <CurriculumVisitList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'office-visits',
        lazy: async () => {
          const { OfficeVisitList } = await import('./pages/monitoring/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <OfficeVisitList />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'office-visits/:visitId',
        lazy: async () => {
          const { OfficeVisitDetails } = await import('./pages/monitoring/index.js');
          return {
            element: (
              <PermissionWrapper
                permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}
                fallback={<AccessDenied />}
              >
                <OfficeVisitDetails />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'monitoring-subjective-report/:formType',
        lazy: async () => {
          const { SubjectiveMonitoring } = await import('./pages/monitoring-bi');
          return {
            element: (
              <PermissionWrapper permissionName={PERMISSIONS.VIEW_REPORT} fallback={<AccessDenied />}>
                <SubjectiveMonitoring />
              </PermissionWrapper>
            ),
          };
        },
      },
      {
        path: 'report',
        children: [
          {
            path: 'institution',
            lazy: async () => {
              const { Institution } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <Institution />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'office',
            lazy: async () => {
              const { Office } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <Office />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'curriculum',
            lazy: async () => {
              const { Curriculum } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <Curriculum />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'others',
            lazy: async () => {
              const { Others } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <Others />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'others/plan-submission',
            lazy: async () => {
              const { PlanSubmission } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <PlanSubmission />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'others/office-institute-summary-report',
            lazy: async () => {
              const { OfficeAndInstituteMonitoringSummary } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <OfficeAndInstituteMonitoringSummary />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'institution/monitoring-plan',
            lazy: async () => {
              const { MonitoringPlan } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <MonitoringPlan />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'institution/monitoring-activities-completed',
            lazy: async () => {
              const { MonitoringActivitiesCompleted } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <MonitoringActivitiesCompleted />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'institution/monitoring-activities-completed-delay',
            lazy: async () => {
              const { MonitoringActivitiesCompletedDelay } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <MonitoringActivitiesCompletedDelay />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'institution/monitoring-activities-incomplete',
            lazy: async () => {
              const { MonitoringActivitiesIncomplete } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <MonitoringActivitiesIncomplete />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'institution/monitoring-teacher-staff-absent-count',
            lazy: async () => {
              const { InstitutionMonitoringTeacherAndStaffAbsentCount } = await import(
                './pages/report'
              );
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <InstitutionMonitoringTeacherAndStaffAbsentCount />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'office/monitoring-summary',
            lazy: async () => {
              const { OfficeMonitoringSummary } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <OfficeMonitoringSummary />
                  </PermissionWrapper>
                ),
              };
            },
          },
          {
            path: 'office/monitoring-officer-staff-absent-count',
            lazy: async () => {
              const { OfficeMonitoringOfficerAndStaffAbsentCount } = await import('./pages/report');
              return {
                element: (
                  <PermissionWrapper
                    permissionName={PERMISSIONS.VIEW_REPORT}
                    fallback={<AccessDenied />}
                  >
                    <OfficeMonitoringOfficerAndStaffAbsentCount />
                  </PermissionWrapper>
                ),
              };
            },
          },
        ],
      },
      {
        path: '/help-center',
        lazy: async () => {
          const { HelpCenter } = await import('./pages/help-center/index.js');
          return { Component: HelpCenter };
        },
      },
      {
        path: 'dynamic-form',
        children: [
          {
            path: 'create',
            lazy: async () => {
              const { CreateFrom } = await import('./pages/dynamic-form/index.ts');
              return { element: <CreateFrom /> };
            },
          },
        ],
      },
    ],
    errorElement: <ServerError />,
  },
  {
    path: '/',
    lazy: async () => {
      const { PublicDashboard } = await import('./pages/dashboard/index.js');
      return { Component: PublicDashboard };
    },
    errorElement: <ServerError />,
  },
  {
    path: 'sso/login',
    lazy: async () => {
      const { Login } = await import('./pages/auth/index.js');
      return { Component: Login };
    },
    errorElement: <ServerError />,
  },
  {
    path: 'login/redirect-to-sso',
    lazy: async () => {
      const { RedirectEmis } = await import('./pages/auth/index.js');
      return { Component: RedirectEmis };
    },
  },
  {
    path: '*',
    element: <NoMatch pageWrapper={'container'} />,
  },
]);
