import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizationNS } from '../../constants/appConstants';
import { not_found_icon } from '../../assets/index';

const NotFound = () => {
  const tCommon = useTranslation(localizationNS.common).t;

  return (
    <div className='d-flex flex-column justify-content-center align-items-center p-5 opacity-50'>
      <div>
        <img src={not_found_icon} width={100} />
      </div>
      <div>
        <h5 className='not-found center'>{tCommon('notFound')}</h5>
      </div>
    </div>
  );
};

export default NotFound;
