import React from 'react';
import { Spinner } from 'react-bootstrap';

const Button = ({ onClick, className, children, disabled, loading, ...rest }) => {
  return (
    <button
      className={`${disabled && 'disable-btn'} app-btn ${className} `}
      onClick={onClick}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <>
          {children} <Spinner size='sm' />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
