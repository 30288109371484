import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { logout_icon, dropdown_icon_white, profile_photo, profile_icon } from '../../assets';
import { en2BnConversion } from '../../utils/helperFunction';
import { generalReducer } from '../../redux/generalSlice';
import { logout } from '../../services/logoutService';
import { authInfo } from '../../pages/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../constants/appRoutes';

const NavProfile = ({
  className,
  toggleClass,
  nameDesignationClass,
  profileNameClass,
  designationNameClass,
  workStationNameClass,
  dropdownItemClassName,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user: {
      imagePath,
      fullNameBangla,
      fullNameEnglish,
      designationBangla,
      designationEnglish,
      activeJurisdiction: { workstationNameEnglish, workstationNameBangla },
    },
  } = useSelector(authInfo);

  const { appLanguage } = useSelector(generalReducer);
  const navigate = useNavigate();

  return (
    <>
      <Dropdown className={`nav-profile ${className}`} align={'end'} {...rest}>
        <Dropdown.Toggle className={`nav-profile-dropdown ${toggleClass}`}>
          <div className='d-flex align-items-center gap-3'>
            <div>
              <img
                src={imagePath || profile_photo}
                className='nav-profile-img'
                alt='Profile picture'
              />
            </div>
            <div className={`nav-name-designation ${nameDesignationClass}`}>
              <div className='d-flex align-items-center'>
                <div className={`profile-name-text ${profileNameClass}`}>
                  {en2BnConversion(fullNameBangla, fullNameEnglish, appLanguage)}
                </div>
                , &nbsp;
                <div className={`profile-designation-text ${designationNameClass}`}>
                  {en2BnConversion(designationBangla, designationEnglish, appLanguage)}
                </div>
              </div>
              <div className={`profile-workstation-text ${workStationNameClass}`}>
                {en2BnConversion(workstationNameBangla, workstationNameEnglish, appLanguage)}
              </div>
            </div>
            <div>
              <img src={dropdown_icon_white} className='img-fluid' alt='dropdown icon' />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              navigate(appRoutes.userProfile);
            }}
            className={dropdownItemClassName}
          >
            <img src={profile_icon} alt='profile icon' />
            <span>{t('topbar.profile')}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              dispatch(logout());
            }}
            className={dropdownItemClassName}
          >
            <img src={logout_icon} alt='logout icon' />
            <span>{t('topbar.logout')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NavProfile;
