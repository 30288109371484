import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserNotification } from '../../pages/notice/notificationApi';
import { getUserNotification } from '../../pages/notice/NotificationSlice';
import { authInfo } from '../../pages/auth/authSlice';
import { bell_icon } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../constants/appRoutes';
import { digitConversion } from '../../utils/helperFunction';
import { generalReducer } from '../../redux/generalSlice';

const NotificationCount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { appLanguage } = useSelector(generalReducer);

  const {
    user: { userId },
  } = useSelector(authInfo);

  const unreadCount = useSelector(getUserNotification)
    .notifications.filter((n) => n.userId === userId)
    .reduce((count, notification) => {
      if (notification.isVisible && !notification.isRead) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

  useEffect(() => {
    dispatch(fetchUserNotification());

    const FETCH_INTERVAL_MIN = process.env.REACT_APP_NOTICE_FETCH_INTERVAL_MIN || 10;
    const intervalId = setInterval(
      () => dispatch(fetchUserNotification()),
      parseInt(FETCH_INTERVAL_MIN) * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='notification-div'>
          <img
            src={bell_icon}
            className='nav-bell-icon img-fluid pointer'
            alt='bell-icon'
            onClick={() => {
              navigate(appRoutes.noticeList);
            }}
          />

          {unreadCount > 0 && (
            <div className='notification-badge'>{digitConversion(unreadCount, appLanguage)}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationCount;
