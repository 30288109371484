import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const AppDropdownButton = ({
  className,
  title,
  items = [],
  disabled = false,
  handleItemClick = () => {
    /* */
  },
}) => {
  return (
    <DropdownButton
      className={`app__dropdown-btn  ${className} ${
        disabled ? 'app_dropdown_disable-btn' : 'border-1-primary'
      } `}
      as={ButtonGroup}
      title={title}
      disabled={disabled}
      align={'start'}
    >
      <>
        {items.map((item, index) => (
          <Dropdown.Item
            key={index}
            eventKey={item.type}
            onClick={() => handleItemClick(item.type)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </>
    </DropdownButton>
  );
};

export default AppDropdownButton;
