import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getJurisdictionWiseZones, toDropdownItems } from '../../utils/helperFunction';
import { locationReducer } from '../../redux/locationSlice';
import { generalReducer } from '../../redux/generalSlice';
import { authInfo } from '../../pages/auth/authSlice';
import Dropdown from './Dropdown';
import Button from './Button';

const AppFilter = ({
  handleParentClearFilter = () => {
    /* */
  },
  checkForParentDropdownError = () => {
    return false;
  },
  shouldUseJurisdiction = true,
  onFilterClick = () => {
    /* */
  },
  onClearFilterClick = () => {
    /* */
  },
  preSelectedZone = '',
  preSelectDistrict = '',
  preSelectUpazila = '',
  clearFilterClassName,
  hideUpazila,
  allRequired,
  onlyZoneRequired,
  actionColSize = 12,
  hideZone,
  filterClassName,
  hideZoneWithId,
  colSize = 3,
  children,
  hideFilterClear,
  actionCustomContent,
  onZoneIdChange = () => {
    /* */
  },
  onDistrictIdChange = () => {
    /* */
  },
  onUpazilaIdChange = () => {
    /* */
  },
  startingComponent,
  disableFilterButton,
}) => {
  const { t } = useTranslation();

  const { user } = useSelector(authInfo);
  const { activeJurisdiction } = user;
  const { locationHierarchy } = useSelector(locationReducer);
  const { appLanguage } = useSelector(generalReducer);

  const [selectedZoneId, setSelectedZoneId] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('');
  const [selectedUpazilaId, setSelectedUpazilaId] = useState('');

  const [zones, setZones] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [upazilas, setUpazilas] = useState([]);

  const [zoneSelectItems, setZoneSelectItems] = useState([]);
  const [districtSelectItems, setDistrictSelectItems] = useState([]);
  const [upazilaSelectItems, setUpazilaSelectItems] = useState([]);

  const [zoneError, setZoneError] = useState(false);
  const [districtError, setDistrictError] = useState(false);
  const [upazilaError, setUpazilaError] = useState(false);

  const [zoneErrorMessage, setZoneErrorMessage] = useState('');
  const [districtErrorMessage, setDistrictErrorMessage] = useState('');
  const [upazilaErrorMessage, setUpazilaErrorMessage] = useState('');

  const handleZoneChange = (zoneId) => {
    setSelectedUpazilaId('');
    setSelectedDistrictId('');
    setSelectedZoneId(zoneId);
    setZoneError(false);
    setZoneErrorMessage(false);
    onZoneIdChange(zoneId);
    const dis = zones.filter((e) => e.id.toString() === zoneId.toString())[0]?.children ?? [];
    let { path } = activeJurisdiction;

    if (!shouldUseJurisdiction) {
      path = '/';
    }
    let splitedPath = path.split('/');
    let extractedDistricts = dis;

    if (splitedPath.length > 3) {
      extractedDistricts = dis.filter((e) => e.id.toString() === splitedPath[2].toString());
    }
    setDistricts(extractedDistricts);
    const districtList = toDropdownItems(
      extractedDistricts,
      appLanguage,
      'id',
      'nameBangla',
      'nameEnglish',
    );
    setDistrictSelectItems(districtList);
  };

  const handleDistrictChange = (districtId) => {
    setSelectedUpazilaId('');
    setSelectedDistrictId(districtId);
    setDistrictError(false);
    setDistrictErrorMessage('');
    onDistrictIdChange(districtId);
    const subDis =
      districts.filter((e) => e.id.toString() === districtId.toString())[0]?.children ?? [];
    let { path } = activeJurisdiction;
    if (!shouldUseJurisdiction) {
      path = '/';
    }
    let splitedPath = path.split('/');
    let extractedUpazilas = subDis;
    if (splitedPath.length > 4) {
      extractedUpazilas = subDis.filter((e) => e.id.toString() === splitedPath[3].toString());
    }
    setUpazilas(extractedUpazilas);
    const upazillaDropdownItems = toDropdownItems(
      extractedUpazilas,
      appLanguage,
      'id',
      'nameBangla',
      'nameEnglish',
    );
    setUpazilaSelectItems(upazillaDropdownItems);
  };

  const handleUpazilaChange = (upazilaId) => {
    setSelectedUpazilaId(upazilaId);
    setUpazilaError(false);
    setUpazilaErrorMessage('');
    onUpazilaIdChange(upazilaId);
  };

  const handleClearFilter = () => {
    handleParentClearFilter();
    if (zoneSelectItems.length > 1 && !hideZone) {
      setSelectedZoneId('');
    }
    if (districtSelectItems.length > 1) {
      setSelectedDistrictId('');
    }
    if (upazilaSelectItems.length > 1 && !hideUpazila) {
      setSelectedUpazilaId('');
    }
    onClearFilterClick();
  };

  const handleFilter = () => {
    if (allRequired && haveAllFieldValue()) {
      return;
    }

    if (onlyZoneRequired && !selectedZoneId) {
      setZoneError(true);
      setZoneErrorMessage(t('zoneDropdownError'));
      return;
    }
    let zoneObj = {
      id: null,
      nameBangla: '',
      nameEnglish: '',
    };
    let districtObj = {
      id: null,
      nameBangla: '',
      nameEnglish: '',
    };
    let upazilaObj = {
      id: null,
      nameBangla: '',
      nameEnglish: '',
    };
    const zone = zones.filter((ele) => ele.id.toString() === selectedZoneId.toString())[0] ?? null;
    if (zone) {
      zoneObj = {
        id: zone.id,
        nameBangla: zone.nameBangla,
        nameEnglish: zone.nameEnglish,
      };
    }
    const district =
      districts.filter((e) => e.id.toString() === selectedDistrictId.toString())[0] ?? null;
    if (district) {
      districtObj = {
        id: district.id,
        nameBangla: district.nameBangla,
        nameEnglish: district.nameEnglish,
      };
    }
    const upazila =
      upazilas.filter((e) => e.id.toString() === selectedUpazilaId.toString())[0] ?? null;

    if (upazila) {
      upazilaObj = {
        ...upazilaObj,
        id: upazila.id,
        nameBangla: upazila.nameBangla,
        nameEnglish: upazila.nameEnglish,
      };
    }

    onFilterClick(zoneObj, districtObj, upazilaObj);
  };

  const haveAllFieldValue = () => {
    let isError = false;
    if (!selectedZoneId && !hideZone) {
      setZoneError(true);
      setZoneErrorMessage(t('zoneDropdownError'));
      isError = true;
    }
    if (!selectedDistrictId) {
      setDistrictError(true);
      setDistrictErrorMessage(t('districtDropdownError'));
      isError = true;
    }
    if (!selectedUpazilaId && !hideUpazila) {
      setUpazilaError(true);
      setUpazilaErrorMessage(t('upazilaDropdownError'));
      isError = true;
    }
    return isError || checkForParentDropdownError();
  };

  useEffect(() => {
    const extractedZones = getJurisdictionWiseZones(
      locationHierarchy,
      shouldUseJurisdiction ? activeJurisdiction.path : '/',
    );
    setZones(extractedZones);
    const zoneSelectItems = toDropdownItems(
      extractedZones,
      appLanguage,
      'id',
      'nameBangla',
      'nameEnglish',
    );

    setZoneSelectItems(zoneSelectItems);
  }, [appLanguage, activeJurisdiction.path, locationHierarchy, shouldUseJurisdiction]);

  useEffect(() => {
    if (zoneSelectItems.length === 1) {
      const zoneId = zoneSelectItems[0].value.toString();
      setSelectedUpazilaId('');
      setSelectedDistrictId('');
      setSelectedZoneId(zoneId);
      setZoneError(false);
      setZoneErrorMessage(false);
      const dis = zones.filter((e) => e.id.toString() === zoneId.toString())[0]?.children ?? [];
      let path = activeJurisdiction.path;

      if (!shouldUseJurisdiction) {
        path = '/';
      }
      let splitedPath = path.split('/');
      let extractedDistricts = dis;

      if (splitedPath.length > 3) {
        extractedDistricts = dis.filter((e) => e.id.toString() === splitedPath[2].toString());
      }
      setDistricts(extractedDistricts);
      const districtList = toDropdownItems(
        extractedDistricts,
        appLanguage,
        'id',
        'nameBangla',
        'nameEnglish',
      );
      setDistrictSelectItems(districtList);
    }
  }, [zoneSelectItems, activeJurisdiction.path, appLanguage, shouldUseJurisdiction, zones]);

  useEffect(() => {
    if (districtSelectItems.length === 1) {
      const districtId = districtSelectItems[0].value.toString();
      setSelectedUpazilaId('');
      setSelectedDistrictId(districtId);
      setDistrictError(false);
      setDistrictErrorMessage('');
      const subDis =
        districts.filter((e) => e.id.toString() === districtId.toString())[0]?.children ?? [];
      let path = activeJurisdiction.path;
      if (!shouldUseJurisdiction) {
        path = '/';
      }
      let splitedPath = path.split('/');
      let extractedUpazilas = subDis;
      if (splitedPath.length > 4) {
        extractedUpazilas = subDis.filter((e) => e.id.toString() === splitedPath[3].toString());
      }
      setUpazilas(extractedUpazilas);
      const upazillaDropdownItems = toDropdownItems(
        extractedUpazilas,
        appLanguage,
        'id',
        'nameBangla',
        'nameEnglish',
      );
      setUpazilaSelectItems(upazillaDropdownItems);
    }
  }, [districtSelectItems, activeJurisdiction.path, appLanguage, districts, shouldUseJurisdiction]);

  useEffect(() => {
    if (upazilaSelectItems.length === 1) {
      const upazilaId = upazilaSelectItems[0].value.toString();
      setSelectedUpazilaId(upazilaId);
      setUpazilaError(false);
      setUpazilaErrorMessage('');
    }
  }, [upazilaSelectItems]);

  useEffect(() => {
    if (hideZone) {
      const zoneId = hideZoneWithId;
      setSelectedUpazilaId('');
      setSelectedDistrictId('');
      setSelectedZoneId(zoneId);
      setZoneError(false);
      setZoneErrorMessage(false);
      const dis = zones.filter((e) => e.id.toString() === zoneId.toString())[0]?.children ?? [];
      let path = activeJurisdiction.path;

      if (!shouldUseJurisdiction) {
        path = '/';
      }
      let splitedPath = path.split('/');
      let extractedDistricts = dis;

      if (splitedPath.length > 3) {
        extractedDistricts = dis.filter((e) => e.id.toString() === splitedPath[2].toString());
      }
      setDistricts(extractedDistricts);
      const districtList = toDropdownItems(
        extractedDistricts,
        appLanguage,
        'id',
        'nameBangla',
        'nameEnglish',
      );
      setDistrictSelectItems(districtList);
    }
  }, [
    hideZone,
    hideZoneWithId,
    activeJurisdiction.path,
    appLanguage,
    shouldUseJurisdiction,
    zones,
  ]);

  useEffect(() => {
    if (preSelectedZone) {
      zoneSelectItems.forEach((e) => {
        if (e.value.toString() === preSelectedZone.toString()) {
          const zoneId = preSelectedZone;
          setSelectedUpazilaId('');
          setSelectedDistrictId('');
          setSelectedZoneId(zoneId);
          setZoneError(false);
          setZoneErrorMessage(false);
          const dis = zones.filter((e) => e.id.toString() === zoneId.toString())[0]?.children ?? [];
          let path = activeJurisdiction.path;

          if (!shouldUseJurisdiction) {
            path = '/';
          }
          let splitedPath = path.split('/');
          let extractedDistricts = dis;

          if (splitedPath.length > 3) {
            extractedDistricts = dis.filter((e) => e.id.toString() === splitedPath[2].toString());
          }
          setDistricts(extractedDistricts);
          const districtList = toDropdownItems(
            extractedDistricts,
            appLanguage,
            'id',
            'nameBangla',
            'nameEnglish',
          );
          setDistrictSelectItems(districtList);
        }
      });
    }
  }, [
    preSelectedZone,
    zoneSelectItems,
    activeJurisdiction.path,
    zones,
    appLanguage,
    shouldUseJurisdiction,
  ]);

  useEffect(() => {
    if (preSelectDistrict) {
      districtSelectItems.forEach((e) => {
        if (e.value.toString() === preSelectDistrict.toString()) {
          const districtId = preSelectDistrict;
          setSelectedUpazilaId('');
          setSelectedDistrictId(districtId);
          setDistrictError(false);
          setDistrictErrorMessage('');
          const subDis =
            districts.filter((e) => e.id.toString() === districtId.toString())[0]?.children ?? [];
          let path = activeJurisdiction.path;
          if (!shouldUseJurisdiction) {
            path = '/';
          }
          let splitedPath = path.split('/');
          let extractedUpazilas = subDis;
          if (splitedPath.length > 4) {
            extractedUpazilas = subDis.filter((e) => e.id.toString() === splitedPath[3].toString());
          }
          setUpazilas(extractedUpazilas);
          const upazillaDropdownItems = toDropdownItems(
            extractedUpazilas,
            appLanguage,
            'id',
            'nameBangla',
            'nameEnglish',
          );
          setUpazilaSelectItems(upazillaDropdownItems);
        }
      });
    }
  }, [
    preSelectDistrict,
    districtSelectItems,
    appLanguage,
    shouldUseJurisdiction,
    activeJurisdiction.path,
    districts,
  ]);

  useEffect(() => {
    if (preSelectUpazila) {
      upazilaSelectItems.forEach((e) => {
        if (e.value.toString() === preSelectUpazila.toString()) {
          const upazilaId = preSelectUpazila;
          setSelectedUpazilaId(upazilaId);
          setUpazilaError(false);
          setUpazilaErrorMessage('');
        }
      });
    }
  }, [preSelectUpazila, upazilaSelectItems]);

  return (
    <div className='row m-0 g-3'>
      {startingComponent}
      <div className={`col-${colSize} ${hideZone && 'd-none'}`}>
        <div>{t('zone')}</div>
        <Dropdown
          title={t('selectZone')}
          value={selectedZoneId}
          selectItems={zoneSelectItems}
          handleChange={handleZoneChange}
          disabled={zoneSelectItems.length === 1}
          error={zoneError}
          errorMessage={zoneErrorMessage}
        />
      </div>
      <div className={`col-${colSize}`}>
        <div>{t('district')}</div>
        <Dropdown
          title={t('selectDistrict')}
          value={selectedDistrictId}
          selectItems={districtSelectItems}
          handleChange={handleDistrictChange}
          disabled={districtSelectItems.length === 1 || !selectedZoneId}
          error={districtError}
          errorMessage={districtErrorMessage}
        />
      </div>
      <div className={`col-${colSize} ${hideUpazila && 'd-none'}`}>
        <span>{t('subDistrict')}</span>
        <Dropdown
          title={t('selectSubDistrict')}
          value={selectedUpazilaId}
          selectItems={upazilaSelectItems}
          handleChange={handleUpazilaChange}
          disabled={upazilaSelectItems.length === 1 || !selectedDistrictId}
          error={upazilaError}
          errorMessage={upazilaErrorMessage}
        />
      </div>
      {children}
      <div
        className={`col-${actionColSize} d-flex justify-content-end gap-2 `}
        style={{ marginTop: '1.5rem' }}
      >
        <Button
          className={`dms-primary-btn  ${filterClassName}`}
          onClick={handleFilter}
          disabled={disableFilterButton}
        >
          {actionCustomContent ? actionCustomContent : t('filter')}
        </Button>
        {!hideFilterClear && (
          <Button className={`bg-transparent ${clearFilterClassName}`} onClick={handleClearFilter}>
            {t('removeFilter')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AppFilter;
