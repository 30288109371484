import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppCard = ({ items = [] }) => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <div className='app__main-card-container'>
      {items.map((ele) => (
        <div
          className='app__card-container pointer'
          key={ele.id}
          onClick={() => handleClick(ele.link)}
        >
          <div className='app__card-body'>
            <div>
              <img src={ele.image} alt={'No Image'} />
            </div>
            <span className='text-primary fw-bold fs-5'>{ele.text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppCard;
